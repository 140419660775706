import { createContext, useMemo, useState } from "react"

interface AuthProviderProps {
    children: any
}

const AuthContext = createContext({})

export function AuthProvider({ children }: AuthProviderProps) {
    const savedMerchant = window.localStorage.getItem("merchantId")
    const [merchantId, setMerchantId] = useState(savedMerchant)

    const [auth, setAuth] = useState({})

    const [sessionState, setSessionState] = useState(
        localStorage.getItem("session_state") || ""
    )
    const [persist, setPersist] = useState(
        JSON.parse(localStorage.getItem("test") ?? "false")
    )
    const [secret, setSecret]= useState({})

    const value = useMemo(
        () => ({
            auth,
            setAuth,
            persist,
            setPersist,
            sessionState,
            setSessionState,
            merchantId,
            setMerchantId,
            secret,
            setSecret
        }),
        [auth, persist, secret, sessionState, merchantId]
    )
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthContext
